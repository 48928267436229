exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-carbon-tsx": () => import("./../../../src/pages/legal/carbon.tsx" /* webpackChunkName: "component---src-pages-legal-carbon-tsx" */),
  "component---src-pages-legal-index-tsx": () => import("./../../../src/pages/legal/index.tsx" /* webpackChunkName: "component---src-pages-legal-index-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-templates-blog-detail-template-tsx": () => import("./../../../src/templates/blog-detail.template.tsx" /* webpackChunkName: "component---src-templates-blog-detail-template-tsx" */),
  "component---src-templates-story-detail-template-tsx": () => import("./../../../src/templates/story-detail.template.tsx" /* webpackChunkName: "component---src-templates-story-detail-template-tsx" */)
}

